import React from 'react'
import { Link } from 'gatsby'

import logo from '../assets/images/logo.svg'

const Footer = props => (
  <footer id="footer">
    <section>
      <h2>Info</h2>
      <p>
        Sed lorem ipsum dolor sit amet et nullam consequat feugiat consequat
        magna adipiscing tempus etiam dolore veroeros. eget dapibus mauris. Cras
        aliquet, nisl ut viverra sollicitudin, ligula erat egestas velit, vitae
        tincidunt odio.
      </p>
    </section>
    <section>
      <h2>Contact</h2>
      <dl className="alt">
        <dt>Address</dt>
        <dd>
          UCL BaseKX &bull; 103c Camley Street &bull; London &bull; N1C 4PF
        </dd>
        <dt>Email</dt>
        <dd>
          <a href="#">y.topazly@sayschoolonline.com</a>
        </dd>
      </dl>
      <ul className="icons">
        <li>
          <a href="#" className="icon fa-twitter alt">
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a href="#" className="icon fa-facebook alt">
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a href="#" className="icon fa-instagram alt">
            <span className="label">Instagram</span>
          </a>
        </li>
      </ul>
    </section>
    <p className="copyright">
      &copy; 2019 SAY Business School Ltd / Pala Consultants Ltd.
    </p>
  </footer>
)

export default Footer
